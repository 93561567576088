import React, { FC } from 'react'

import DateComponent from '../date/Date'
import MPU from '../mpu/MPU'
import { fireEvent } from '../tracking/Tracking'

interface ArticleMetadataProps {
    published_date: string
    first_published_date: string
    author?: Array<{ name: string }>
    image?: {
        image: string
    }
    noContainer?: boolean
    isPartnerContent?: boolean
    byline?: string
}

const ArticleMetadata: FC<ArticleMetadataProps> = (
    props = {
        published_date: '',
        first_published_date: '',
        noContainer: false,
        author: undefined,
        image: undefined,
        isPartnerContent: false,
        byline: '',
    }
) => {
    const className = props.noContainer ? '' : 'o-grid-container'

    const printArticle = () => {
        fireEvent(
            {
                category: 'cta',
                action: 'click',
                'data-trackable': 'Print button',
                context: {
                    text: 'Print',
                    url: window.location.href,
                    className: 'ft-print-icon',
                },
            },
            true
        )

        window.print()
    }

    const byline = props.byline || ''

    const colspanClasses = props.isPartnerContent
        ? '12 Mpush1 Lpush2 M10 L8'
        : '12 Mpush1 M10 L7'

    const Authors = () => {
        return (
            <div
                className='article-metadata__authors'
                dangerouslySetInnerHTML={{
                    __html: byline,
                }}
            />
        )
    }

    return (
        <div className={`${className} article-metadata-container`}>
            <div className='article-metadata o-grid-row'>
                <div
                    className='article-metadata__text'
                    data-o-grid-colspan={colspanClasses}
                >
                    {!props.isPartnerContent ? <Authors /> : null}
                    <div className='article-metadata__dates'>
                        {props.first_published_date && (
                            <div>
                                <span className='article-metadata__date-label'>
                                    Published{' '}
                                </span>
                                <DateComponent
                                    id='article-published-date'
                                    className='article-metadata__date'
                                    dateTime={props.first_published_date}
                                />
                            </div>
                        )}
                        {props.published_date && props.first_published_date && (
                            <span className='article-metadata__date-separator'>
                                |
                            </span>
                        )}
                        {props.published_date && (
                            <div>
                                <span className='article-metadata__date-label'>
                                    Updated{' '}
                                </span>
                                <DateComponent
                                    id='article-updated-date'
                                    className='article-metadata__date'
                                    dateTime={props.published_date}
                                    format='MMMM D yyyy, h:mm'
                                />
                            </div>
                        )}
                        <img
                            onClick={printArticle}
                            className='article-metadata__print'
                            src='https://static.fdiintelligence.com/assets/common/icon-print.svg'
                        />
                    </div>
                </div>
                {!props.image && (
                    <div
                        className='article-metadata__advertisement'
                        data-o-grid-colspan='0 Lpull0 L3'
                    >
                        <MPU type='mid' initAds />
                    </div>
                )}
            </div>
        </div>
    )
}

export default ArticleMetadata
