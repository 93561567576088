import React from 'react'

import ArticleHeaderImage from '../../../article-header-image/ArticleHeaderImage'
import ArticleHeaderImageFull from '../../../article-header-image/ArticleHeaderImageFull'
import ArticleMetadata from '../../../article-metadata/ArticleMetadata'
import ArticleSocialMediaButtons from '../../../article-social-media-buttons/ArticleSocialMediaButtons'
import ArticleTeaser from '../../../article-teaser/ArticleTeaser'
import ArticleTitle from '../../../article-title/ArticleTitle'
import ArticlePartners from '../../../partners/Partners'
import ArticleSponsors from '../../../sponsors/Sponsors'
import Tag from '../../../tag/Tag'
import ArticleBanner from '../../../article-banner/ArticleBanner'
import { Article } from '../../../../types'
import Hydration from '../../../Hydration'

const ArticleHeaderAdvertorial = ({
    article,
    colour,
}: {
    article: Article
    colour: string
}) => {
    const {
        title,
        image,
        hide_header_image: hideHeaderImage,
        teaser,
        teaser_image_caption,
        advertorial_sponsor: advertorialSponsor,
        partner_content: partnerContent,
        published_date: publishedDate,
        first_published_date: firstPublishedDate,
        teaser_image_caption: teaserImageCaption,
    } = article

    return (
        <div className='article-header article-header-advertorial'>
            <ArticleBanner
                article={article}
                colour={colour}
                fullWidth={!!partnerContent}
            >
                {partnerContent && (
                    <>
                        <ArticlePartners partners={partnerContent} />
                        {!hideHeaderImage && (
                            <Hydration id='article-header-image-full'>
                                <ArticleHeaderImageFull
                                    image={image}
                                    caption={teaserImageCaption}
                                />
                            </Hydration>
                        )}
                    </>
                )}
                <div className='o-grid-container'>
                    <ArticleTitle
                        title={title}
                        white={!partnerContent}
                        hideHeaderImage={hideHeaderImage}
                        isPartnerContent={!!partnerContent}
                    />
                    {!partnerContent && !advertorialSponsor && (
                        <Tag type='advertorial' colspan='Mpush1' />
                    )}
                    <ArticleTeaser
                        teaser={teaser}
                        white={!partnerContent}
                        isPartnerContent={!!partnerContent}
                    />
                    {!partnerContent && (
                        <ArticleSponsors sponsors={advertorialSponsor} />
                    )}
                </div>
            </ArticleBanner>
            <div className='article__social-media-container'>
                <div className='o-grid-container'>
                    {!partnerContent && (
                        <ArticleHeaderImage
                            image={image}
                            teaser_image_caption={teaser_image_caption}
                        />
                    )}
                    <Hydration id='article-social-media-buttons'>
                        <ArticleSocialMediaButtons
                            title={title}
                            teaser={teaser}
                        />
                    </Hydration>
                </div>
                <ArticleMetadata
                    published_date={publishedDate}
                    first_published_date={firstPublishedDate}
                    image={image}
                    isPartnerContent={!!partnerContent}
                    byline={article.byline}
                />
            </div>
        </div>
    )
}

export default ArticleHeaderAdvertorial
