import React, { FC } from 'react'

import Image from '../image/Image'
import { ArticleRichAttachement } from '../../types'

const Attachment: FC<ArticleRichAttachement> = props => {
    if (!props.title || !props.file) {
        return null
    }

    return (
        <div className='attachment'>
            <a
                href={props.file.downloadLink}
                onClick={() => false}
                className='attachment__download-link'
                data-trackable='PDF Download'
                data-o-tracking-skip-queue='true'
                target='_blank'
                rel='noopener noreferrer'
            >
                {props.image && (
                    <div className='attachment__image'>
                        <Image src={props.image} />
                    </div>
                )}
                <div className='attachment__data'>
                    <div className='attachment__data-text'>
                        <div className='attachment_title'>{props.title}</div>
                        <div
                            className='teaser'
                            dangerouslySetInnerHTML={{ __html: props.teaser }}
                        />
                    </div>

                    <div className='attachment__data-info'>
                        <Image src='https://static.fdiintelligence.com/assets/common/icon_download_white.png' />
                        <div className='info__text'>
                            <span
                                className={`info__text-file-type ${!props.file.size ? 'info__text-file-type-no-size' : ''}`}
                            >
                                PDF
                            </span>
                            {props.file.size && (
                                <span className='info__text-file-size'>
                                    {props.file.size}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </a>
        </div>
    )
}

export default Attachment
