import React, { FC, useEffect } from 'react'
import { Report as ReportType } from '../../types'
import ReportHeader from '../report-header/ReportHeader'
import Sponsors from '../sponsors/Sponsors'
import Attachment from '../attachment/Attachment'
import SectionTitle from '../section-title/SectionTitle'
import ArticleListItem from '../article-list-item/ArticleListItem'
import MPUContainer from '../mpu/mpu-container/MPUContainer'
import { initTracking } from '../tracking/Tracking'
import { updateAdsConfig } from '../utils/updateAdsConfig'

const SpecialReport: FC<{
    report: ReportType
}> = ({ report }) => {
    useEffect(() => {
        initTracking('report')
    }, [])

    useEffect(() => {
        updateAdsConfig({
            uuid: report.id,
            asset_type: 'special_report',
            pt: 'art',
        })
    }, [report])

    if (!report) return null

    const linkMatch = report.description?.match(
        /<a[^>]+href="([^"]+\.pdf)"[^>]*>(.*?)<\/a>/
    )

    const attachmentUrlFromDescription = linkMatch?.[1]
    const attachmentTextFromDescription = linkMatch?.[2]?.trim()

    const cleanedDescription = report.description
        ?.replace(
            /<p[^>]*>\s*<a[^>]+href="[^"]+\.pdf"[^>]*>.*?<\/a>\s*<\/p>/i,
            ''
        )
        .trim()

    const modifiedReport = {
        ...report,
        description: cleanedDescription,
    }

    const hasPdfInDescription = Boolean(attachmentUrlFromDescription)

    const attachmentProps = hasPdfInDescription
        ? {
              ...report.attachment,
              title: report.title,
              image: report.image.image,
              teaser: attachmentTextFromDescription || '',
              file: {
                  ...report.attachment?.file,
                  downloadLink: attachmentUrlFromDescription ?? '',
              },
          }
        : report.attachment
    return (
        <>
            <ReportHeader {...modifiedReport} />
            <div className='o-grid-container'>
                <Sponsors sponsors={report.sponsor} colSpan='12' />
                <div className='o-grid-row'>
                    <div data-o-grid-colspan='12 L8'>
                        <Attachment {...(attachmentProps || {})} />
                        <div className='o-grid-row'>
                            <SectionTitle titleContent='Articles in this report' />
                        </div>
                        {report.articles.slice(0, 5).map((article, i) => (
                            <ArticleListItem
                                article={article}
                                key={article.id}
                                withTopSeparator={i !== 0}
                            />
                        ))}
                        <div className='bottom-ad'>
                            <div
                                className='o-ads o-ads--center o-ads--placeholder'
                                data-o-ads-formats-default='false'
                                data-o-ads-formats-large='Leaderboard'
                                data-o-ads-formats-extra='SuperLeaderboard'
                                aria-hidden='true'
                            />
                        </div>
                    </div>
                    <div data-o-grid-colspan='12 L4'>
                        <MPUContainer type='mid' />
                    </div>
                    <div data-o-grid-colspan='12 L8'>
                        {report.articles.slice(5).map(article => (
                            <ArticleListItem
                                article={article}
                                key={article.id}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SpecialReport
