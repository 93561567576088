/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react'

import ThreeArticlesBlock from '../three-articles-block/ThreeArticlesBlock'
import ArticleList from '../article-list/ArticleList'

interface ArticleListItem {
    id: string | number
    title: string
    teaser?: string
    url: string
    tags: string[]
    image: { image: string }
    published_date: string
    advertorial_sponsor: { name: string; url: string }[]
    partner_content: { name: string; url: string }[]
    location: string
    start_date: string
    end_date: string
    eventType: string
}

interface EventsHubRelatedArticlesProps {
    articles: ArticleListItem[]
}

const EventsHubRelatedArticles: FC<EventsHubRelatedArticlesProps> = ({
    articles = [],
}) => {
    if (articles.length === 0) return null

    let visuallyStrongArticles = null
    let listArticles

    if (articles.length > 2) {
        visuallyStrongArticles = articles.slice(0, 3)
        listArticles = articles.slice(3)
    } else {
        listArticles = articles
    }

    return (
        <div className='events-hub-related-articles'>
            <div className='events-hub-related-articles__latest o-grid-row'>
                {visuallyStrongArticles !== null && (
                    <ThreeArticlesBlock
                        title='Related News'
                        articles={visuallyStrongArticles as any}
                        showImage={true}
                        showDate={true}
                    />
                )}
                {listArticles.length !== 0 && (
                    <ArticleList
                        articles={listArticles as any}
                        withTopSeparator
                    />
                )}
            </div>
        </div>
    )
}

export default EventsHubRelatedArticles
